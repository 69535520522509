import React from 'react';
import '../styles/About.css';  // Consider creating a CSS file for styling this specific page

const About = () => {
    return (
        <div className="about">
            <h1>About Us</h1>
            <p>
                (empty) orchestra; A multidisciplinary creative collective/label. Established 2022 in Gothenburg, Sweden.

                We are an open-source corporate, we allow any form of interaction; listening, sampling, remaking, repurposing, anything, everything and nothing.
                There is no goal, there is no point and there is no objective.
            </p>
            <img src="assets/images/logos/eo art nr 487 trans.png" alt=""/>
        </div>
    );
};

export default About;
